@font-face {
  font-family: "Lato";
  src: url("assets/font/Lato-Bold.woff2") format("woff2"),
    url("assets/font/Lato-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("assets/font/Lato-BlackItalic.woff2") format("woff2"),
    url("assets/font/Lato-BlackItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("assets/font/Lato-Black.woff2") format("woff2"),
    url("assets/font/Lato-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("assets/font/Lato-LightItalic.woff2") format("woff2"),
    url("assets/font/Lato-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("assets/font/Lato-Italic.woff2") format("woff2"),
    url("assets/font/Lato-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("assets/font/Lato-BoldItalic.woff2") format("woff2"),
    url("assets/font/Lato-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("assets/font/Lato-Light.woff2") format("woff2"),
    url("assets/font/Lato-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Lato Hairline";
  src: url("assets/font/Lato-Hairline.woff2") format("woff2"),
    url("assets/font/Lato-Hairline.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("assets/font/Lato-Regular.woff2") format("woff2"),
    url("assets/font/Lato-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Lato Hairline";
  src: url("assets/font/Lato-HairlineItalic.woff2") format("woff2"),
    url("assets/font/Lato-HairlineItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0;
}

p {
  margin-bottom: 0;
}

body {
  font-family: "Lato";
}

.body-bg {
  background: linear-gradient(
    180deg,
    rgba(239, 246, 255, 0.9) 0%,
    rgba(255, 255, 255, 0.9) 100%
  );
  backdrop-filter: blur(80px);
  height: 100vh;
}

/* responsive */
